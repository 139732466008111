export default [
  {
    path: '/device',
    name: 'device',
    component: () => import('@/views/device/Device.vue'),
  },
  {
    path: '/device-2',
    name: 'device-2',
    component: () => import('@/views/device2/Device2.vue'),
  },
  {
    path: '/remote',
    name: 'remote',
    component: () => import('@/views/remote/Remote-1/Remote.vue'),
  },
  {
    path: '/remote-2',
    name: 'remote-2',
    component: () => import('@/views/remote/Remote-2/Remote2.vue'),
  },
  {
    path: '/alerts',
    name: 'alerts',
    component: () => import('@/views/alerts/Alerts.vue'),
  },
  {
    path: '/alerts',
    name: 'alerts-edit',
    component: () => import('@/views/alerts/AlertsInfoEdit.vue'),
  },
]
